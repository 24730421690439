import { useEffect, useState } from "react";
import { LuPen } from "react-icons/lu";
import { Link, useParams } from "react-router-dom";
import apiClient from "../../api-services/ApiClient";
import AdminLayout from "../../app-components/AdminLayout";
import Preloader from "../../app-components/preloader/Preloader";
import { SITELINKS } from "../../app-routes/links";
import { Icon_Location } from "../../assets/Icons";
import { UserIcon, User_square_no_rounded } from "../../assets/img";
import { UserInterface } from "../../interface/users.interface";
import { formatDate } from "../../utils";

const ShowUser = () => {
  const [userData, setUserData] = useState<Partial<UserInterface>>({});
  const [loading, setLoading] = useState<boolean>(false);

  // Get the 'id' parameter from the URL
  const { id } = useParams<{ id: string }>();

  // effect for get users list
  useEffect(() => {
    if (id) getUser(id);
  }, [id]);

  const getUser = async (id: string) => {
    setLoading(true);
    try {
      const res: any = await apiClient.get(`users/${id}`);

      if (res && res.data) {
        setUserData(res.data);
      } else {
        setUserData({});
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setUserData({});
    } finally {
      setLoading(false);
    }
  };

  return (
    <AdminLayout title="Admin | View Users">
      <div className="flex justify-between items-center mb-5">
        <p className="font-semibold text-[25px]">User Profile</p>

        <Link
          to={`${SITELINKS.UPDATE_USER}${id}`}
          type="submit"
          className="bg-white text-honeConnectPurple p-2 px-4 rounded-[5px] border border-solid border-honeConnectPurple text-sm font-semibold flex items-center gap-2 hover:bg-honeConnectPurple hover:text-white hover:transition-all"
        >
          <span className="text-lg">
            <LuPen />
          </span>
          <span>Edit User</span>
        </Link>
      </div>

      <div className="p-5 pr-0 bg-white rounded-lg shadow mb-5">
        <div className="flex items-center justify-between">
          <div className="flex gap-3 items-center">
            <div>
              <img
                src={userData.profile_pix_link ?? UserIcon}
                alt=""
                className="w-28 border-[3px] rounded-xl border-honeConnectPurple"
              />
            </div>

            <div className="flex flex-col gap-2">
              <p className="text-honeConnectBlack font-medium text-base">
                {userData?.full_name}
              </p>

              <div className="flex items-center gap-2">
                <img src={Icon_Location} alt="" />
                <span className="font-normal text-sm text-honeConnectGray">
                  {[
                    userData?.area,
                    userData?.city?.name,
                    userData?.state?.name,
                    userData?.country?.name,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </span>
              </div>

              <div>
                {userData?.status === "ACTIVE" && (
                  <span className="bg-[#E7F7EF] text-[#0CAF60] py-1 px-6 rounded-[5px] text-sm font-medium">
                    Active
                  </span>
                )}
                {userData?.status === "REMOVED" && (
                  <span className="bg-red-100 text-red-800 py-1 px-6 rounded-[5px] text-sm font-medium">
                    Removed
                  </span>
                )}
                {userData?.status === "FLAGGED" && (
                  <span className="bg-orange-100 text-yellow-800 py-1 px-6 rounded-[5px] text-sm font-medium">
                    Flagged
                  </span>
                )}
                {userData?.status === null && (
                  <span className="hidden bg-purple-200 text-purple-800 py-1 px-6 rounded-[5px] text-sm font-medium">
                    Pending
                  </span>
                )}
              </div>
            </div>
          </div>

          <button
            type="button"
            className="bg-honeConnectPurple text-white p-3 px-4 rounded-[5px] rounded-r-none border border-solid border-honeConnectPurple text-sm font-semibold flex items-center gap-2 hover:bg-white hover:text-honeConnectPurple hover:transition-all"
          >
            {userData?.badge ?? "STANDARD"}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-12 md:col-span-6 xl:col-span-5">
          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <p className="font-medium text-xl mb-4 text-[#202224]">
              Account Information
            </p>

            <div>
              {/* <!-- Fullname --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="FullName" className="mb-1.5">
                  Fullname
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.full_name}
                </div>
              </div>

              {/* <!-- User ID / Profile Name --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="userID" className="mb-1.5">
                  User ID / Profile Name
                </label>

                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.username}
                </div>
              </div>

              {/* <!-- Date of Birth --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="dob" className="mb-1.5">
                  Date of Birth
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {formatDate(userData?.DOB ?? "", "date")}
                </div>
              </div>

              {/* <!-- Gender --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="gender" className="mb-1.5">
                  Gender
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.gender}
                </div>
              </div>

              {/* <!-- Email --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="FullName" className="mb-1.5">
                  Email
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.email}
                </div>
              </div>

              {/* <!-- Phone Number --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="number" className="mb-1.5">
                  Phone Number
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.phone_number}
                </div>
              </div>

              {/* <!-- Country --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  Country
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.country?.name}
                </div>
              </div>

              {/* <!-- State --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  State
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.state?.name}
                </div>
              </div>

              {/* <!-- City --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  City
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.city?.name}
                </div>
              </div>

              {/* <!-- Date Registered --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  Area
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.area}
                </div>
              </div>

              {/* <!-- Date Registered --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  Date Registered
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {formatDate(userData?.created_at ?? "")}
                </div>
              </div>

              {/* <!-- Date Registered --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="date-registered" className="mb-1.5">
                  Date Updated
                </label>
                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {formatDate(userData?.updated_at ?? "")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 xl:col-span-5">
          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">Bio</p>
            </div>
            <div className="rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 p-5 mb-5">
              <p className="text-sm text-honeConnectBlack font-normal min-h-[240px]">
                {userData?.bio}
              </p>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <p className="font-medium text-xl mb-4 text-[#202224]">
              Social Profiles
            </p>
            <div>
              {/* <!-- Instagram ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="instagramID" className="mb-1.5">
                  Instagram ID
                </label>

                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.instagram_id ?? "nil"}
                </div>
              </div>
              {/* <!-- Snapchat ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="snapchatID" className="mb-1.5">
                  Snapchat ID
                </label>

                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.snapchat_id ?? "nil"}
                </div>
              </div>
              {/* <!-- X ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="xID" className="mb-1.5">
                  X ID
                </label>

                <div className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal">
                  {userData?.twitter_x_id ?? "nil"}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Interest
              </p>
            </div>
            <div className="flex flex-wrap gap-4 mt-4">
              <p className="text-sm py-1 px-2 border border-solid border-honeConnectGray200 bg-honeConnectGray50 font-medium text-honeConnectGray rounded-[5px]">
                {userData?.interest ?? "no interest"}
              </p>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Notication Summary
              </p>
            </div>

            <div className="flex gap-8 items-center">
              <div className="flex gap-4 items-center">
                <label
                  htmlFor="toggle"
                  className="text-sm text-honeConnectGray font-medium"
                >
                  Email notification:
                </label>
                <div
                  className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                    userData.is_email_notification !== "0"
                      ? "bg-honeConnectPurple"
                      : "bg-gray-300"
                  }`}
                >
                  {/* Switch */}
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                      userData.is_email_notification !== "0"
                        ? "translate-x-6"
                        : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Account Privacy
              </p>
            </div>

            <div className="flex gap-8 items-center">
              <div className="flex gap-4 items-center">
                <label
                  htmlFor="toggle"
                  className="text-sm text-honeConnectGray font-medium"
                >
                  Private account:
                </label>
                <div
                  className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                    userData.is_private !== "0"
                      ? "bg-honeConnectPurple"
                      : "bg-gray-300"
                  }`}
                >
                  {/* Switch */}
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                      userData.is_private !== "0"
                        ? "translate-x-6"
                        : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-12 xl:col-span-2">
          <div className="bg-white p-5 rounded-lg shadow">
            <div className="flex flex-col sm:flex-row xl:flex-col sm:gap-5 xl:gap-0">
              {/* <!-- ROW --> */}
              <div className="text-sm font-semibold flex justify-between items-center mb-5">
                <p className="text-lg font-medium text-honeConnectBlack">
                  Photos
                </p>
              </div>
              <div className="flex gap-10 sm:gap-5 xl:gap-0 sm:flex-row xl:flex-col">
                {/* <!-- ROW --> */}
                <div className="text-center xl:mb-4">
                  <div className="bg-[#F8F8F8] pb-2 rounded-lg mb-2">
                    <img
                      src={User_square_no_rounded}
                      alt=""
                      className="mb-1 w-36"
                    />
                    <span className="text-sm font-medium text-honeConnectGray">
                      Photo 1
                    </span>
                  </div>
                </div>
                <div className="text-center">
                  <div className="bg-[#F8F8F8] pb-2 rounded-lg mb-2">
                    <img
                      src={User_square_no_rounded}
                      alt=""
                      className="mb-1 w-36"
                    />
                    <span className="text-sm font-medium text-honeConnectGray">
                      Photo 1
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Preloader loading={loading} />
    </AdminLayout>
  );
};

export default ShowUser;
