// Site link declaration
export enum SITELINKS {
  SIGNIN = "/sign-in",
  VERIFY_SIGNIN = "/verify-sign-in",
  BROADCAST = "/broadcast",
  BROADCAST_HISTORY = "/broadcast-history",
  DASHBOARD = "/dashboard",
  REQUEST = "/request",
  RESPONSES = "/responses",
  SUBSCRIPTIONS = "/subscriptions",
  TRANSACTIONS = "/transactions",
  USERS = "/users",
  CREATE_USER = "/user/create",
  UPDATE_USER = "/user/update/",
  USER_PROFILE = "/user/",
}
