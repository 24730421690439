import AdminLayout from "../../app-components/AdminLayout";
import { useState, FormEvent, useEffect } from "react";
import { UserFormType, UserFormSchema } from "../../interface/users.interface";
import { Link } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { LuArrowLeft } from "react-icons/lu";
import apiClient from "../../api-services/ApiClient";
import { successAlert } from "../../utils/SweetAlert";
import Preloader from "../../app-components/preloader/Preloader";
import Alert from "../../app-components/alert/Alert";

const CreateProfile = () => {
  const [serverError, setServerError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [countries, setCountries] = useState<
    {
      name: string;
      nationality: string;
      id: any;
    }[]
  >([]);
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [formData, setFormData] = useState<Partial<UserFormType>>({
    full_name: "",
    email: "",
    username: "",
    DOB: "",
    gender: "",
    country_id: null,
    state_id: null,
    city_id: null,
    interest: null,
    area: "",
    phone_number: "",
    instagram_id: "",
    snapchat_id: "",
    profile_pix_link: null, // File input
    bio: "",
    is_private: false,
    is_email_notification: false,
    twitter_x_id: "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof UserFormType, string>>
  >({});

  useEffect(() => {
    handleCountries();
  }, []);

  useEffect(() => {
    if (formData.country_id) handleStates(Number(formData.country_id) ?? 0);
  }, [formData.country_id]);

  useEffect(() => {
    if (formData.state_id) handleCities(Number(formData.state_id) ?? 0);
  }, [formData.state_id]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | any
    >
  ) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleToggle = (input: any, value?: boolean) => {
    setFormData({
      ...formData,
      [input]: value,
    });
  };

  const handleCreateUser = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});
    setServerError("");
    const result = UserFormSchema.safeParse(formData);

    if (result.success) {
      try {
        setLoading(true);
        await apiClient.post("users", result.data);
        successAlert();
        setFormData({
          full_name: "",
          email: "",
          username: "",
          DOB: "",
          gender: "",
          country_id: null,
          area: "",
          phone_number: "",
          instagram_id: "",
          snapchat_id: "",
          profile_pix_link: null,
          bio: "",
          is_private: false,
          is_email_notification: false,
          twitter_x_id: "",
        });
      } catch (error: any) {
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<Record<keyof UserFormType, string>> = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof UserFormType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);

      setServerError("One or more validation error occured");
    }
  };

  const handleCountries = async () => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get("countries");
      setCountries(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch countries");
    } finally {
      setPageLoading(false);
    }
  };

  const handleStates = async (country: number) => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get(`countries/${country}/states`);
      setStates(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch states");
    } finally {
      setPageLoading(false);
    }
  };

  const handleCities = async (state: number) => {
    setPageLoading(true);
    setAlertMessage("");
    try {
      const res: any = await apiClient.get(`states/${state}/cities`);
      setCities(res.data);
    } catch (error) {
      console.error("Error fetching users:", error);
      setAlertMessage("Unable to fetch countries");
    } finally {
      setPageLoading(false);
    }
  };

  return (
    <AdminLayout title="Admin | Create Users">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-[25px]">Create Profile</p>

        <Link
          to={SITELINKS.USERS}
          type="submit"
          className="bg-white text-red-600 p-2 px-4 rounded-[5px] border border-solid border-red-600 text-sm font-semibold flex items-center gap-2 hover:bg-red-600 hover:text-white hover:transition-all"
        >
          <span className="text-2xl">
            <LuArrowLeft />
          </span>
          <span className="">Go Back</span>
        </Link>
      </div>

      <form
        onSubmit={handleCreateUser}
        autoComplete="off"
        autoCorrect="off"
        className="grid grid-cols-12 gap-5 mt-3"
      >
        <div className="col-span-12 md:col-span-6 xl:col-span-6">
          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <p className="font-medium text-xl mb-4 text-[#202224]">
              Account Information
            </p>

            <div>
              {/* <!-- Fullname --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="FullName" className="mb-1.5">
                  Fullname
                </label>
                <input
                  value={formData.full_name}
                  type="text"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="Iffi Samuel"
                  name="full_name"
                  onChange={handleChange}
                />

                <span className="text-red-600 text-[10px]">
                  {errors.full_name}
                </span>
              </div>

              {/* <!-- User ID / Profile Name --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="userID" className="mb-1.5">
                  User ID / Profile Name
                </label>
                <input
                  value={formData.username}
                  type="text"
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="sam84"
                  name="username"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">
                  {errors.username}
                </span>
              </div>

              {/* <!-- Date of Birth --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="dob" className="mb-1.5">
                  Date of Birth
                </label>
                <input
                  value={formData.DOB}
                  type="date"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder=""
                  name="DOB"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">{errors.DOB}</span>
              </div>

              {/* <!-- Gender --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="gender" className="mb-1.5">
                  Gender
                </label>
                <select
                  value={formData.gender}
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  name="gender"
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option value="MALE">Male</option>
                  <option value="FEMALE">Female</option>
                  <option value="OTHERS">Others</option>
                </select>
                <span className="text-red-600 text-[10px]">
                  {errors.gender}
                </span>
              </div>

              {/* <!-- Email --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="FullName" className="mb-1.5">
                  Email
                </label>
                <input
                  value={formData.email}
                  type="email"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="@adebayotaiwo8734@gmail.com"
                  name="email"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">{errors.email}</span>
              </div>

              {/* <!-- Phone Number --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="number" className="mb-1.5">
                  Phone Number
                </label>
                <input
                  value={formData.phone_number}
                  type="tel"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="234 80 000 0000"
                  name="phone_number"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">
                  {errors.phone_number}
                </span>
              </div>

              {/* <!-- country --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="gender" className="mb-1.5">
                  Country
                </label>
                <select
                  value={formData.country_id ?? ""}
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  name="country_id"
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>

                  {!!countries.length &&
                    countries?.map((item: any) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </select>
                <span className="text-red-600 text-[10px]">
                  {errors.country_id}
                </span>
              </div>

              {/* <!-- state --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="gender" className="mb-1.5">
                  State
                </label>
                <select
                  value={formData.state_id ?? ""}
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  name="state_id"
                  onChange={handleChange}
                >
                  <option value="">Select State</option>

                  {!!states.length &&
                    states?.map((item: any) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </select>
                <span className="text-red-600 text-[10px]">
                  {errors.state_id}
                </span>
              </div>

              {/* <!-- city --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="city_id" className="mb-1.5">
                  city
                </label>
                <select
                  value={formData.city_id ?? ""}
                  className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  name="city_id"
                  onChange={handleChange}
                >
                  <option value="">Select closest city</option>

                  {!!cities.length &&
                    cities?.map((item: any) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </select>
                <span className="text-red-600 text-[10px]">
                  {errors.city_id}
                </span>
              </div>

              {/* <!-- Area --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="FullName" className="mb-1.5">
                  Area
                </label>
                <input
                  value={formData.area}
                  type="text"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="Ikeja"
                  name="city"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">{errors.area}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6 xl:col-span-6">
          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">Bio</p>
            </div>
            <textarea
              value={formData.bio}
              className="w-full h-[130px] rounded-10px bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-5"
              placeholder="Enter bio"
              name="bio"
              onChange={handleChange}
            ></textarea>
            <span className="text-red-600 text-[10px]">{errors.bio}</span>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <p className="font-medium text-xl mb-4 text-[#202224]">
              Social Profiles
            </p>
            <div>
              {/* <!-- Instagram ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="instagramID" className="mb-1.5">
                  Instagram ID
                </label>
                <input
                  value={formData.instagram_id}
                  type="text"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="@adebayot"
                  name="instagram_id"
                  onChange={handleChange}
                />

                <span className="text-red-600 text-[10px]">
                  {errors.instagram_id}
                </span>
              </div>
              {/* <!-- Snapchat ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="snapchatID" className="mb-1.5">
                  Snapchat ID
                </label>
                <input
                  value={formData.snapchat_id}
                  type="text"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="@adebayot"
                  name="snapchat_id"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">
                  {errors.snapchat_id}
                </span>
              </div>
              {/* <!-- X ID --> */}
              <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
                <label htmlFor="xID" className="mb-1.5">
                  X ID
                </label>
                <input
                  value={formData.twitter_x_id}
                  type="text"
                  className="w-[100%] bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                  placeholder="@adebayot"
                  name="twitter_x_id"
                  onChange={handleChange}
                />
                <span className="text-red-600 text-[10px]">
                  {errors.twitter_x_id}
                </span>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Account Privacy
              </p>
            </div>

            <div className="flex gap-8 items-center">
              <div className="flex gap-4 items-center">
                <label
                  htmlFor="toggle"
                  className="text-sm text-honeConnectGray font-medium"
                >
                  Private account:
                </label>
                <div
                  className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                    formData.is_private ? "bg-honeConnectPurple" : "bg-gray-300"
                  }`}
                  onClick={() =>
                    handleToggle("is_private", !formData.is_private)
                  }
                >
                  {/* Switch */}
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                      formData.is_private ? "translate-x-6" : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>

            <span className="text-red-600 text-[10px]">
              {errors.is_private}
            </span>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            {/* <!-- ROW --> */}
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Notification settings
              </p>
            </div>

            <div className="flex gap-8 items-center">
              <div className="flex gap-4 items-center">
                <label
                  htmlFor="toggle"
                  className="text-sm text-honeConnectGray font-medium"
                >
                  Email Notification:
                </label>
                <div
                  className={`w-12 h-6 flex items-center bg-gray-300 rounded-full p-1 cursor-pointer ${
                    formData.is_email_notification
                      ? "bg-honeConnectPurple"
                      : "bg-gray-300"
                  }`}
                  onClick={() =>
                    handleToggle(
                      "is_email_notification",
                      !formData.is_email_notification
                    )
                  }
                >
                  {/* Switch */}
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
                      formData.is_email_notification
                        ? "translate-x-6"
                        : "translate-x-0"
                    }`}
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white p-5 rounded-lg shadow mb-3">
            <div className="text-sm font-semibold flex justify-between items-center mb-5">
              <p className="text-lg font-medium text-honeConnectBlack">
                Interest
              </p>
            </div>

            <div className="flex flex-col text-sm text-gray-400 font-medium mb-4">
              <select
                className="w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-gray-800 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal"
                name="interest"
                onChange={handleChange}
              >
                <option value="" disabled>Select</option>
                <option value={"MINGLE"}>Mingle</option>
                <option value={"FRIENDS"}>Friends</option>
                <option value={"DATING"}>Dating</option>
                <option value={"RELATIONSHIP"}>Relationship</option>
                <option value={"CONVO"}>Convo</option>
              </select>
              <span className="text-red-600 text-[10px]">
                {errors.interest}
              </span>
            </div>
          </div>
        </div>

        <p className="col-span-12 text-red-600 text-[12px] text-center mb-2">
          {serverError}
        </p>
        <div className="col-span-4"></div>
        <button
          type="submit"
          disabled={loading}
          className="col-span-4 block bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base"
        >
          {loading ? "Loading..." : "Create Account"}
        </button>
      </form>

      <Preloader loading={pageLoading} />
      <Alert message={alertMessage} />
    </AdminLayout>
  );
};

export default CreateProfile;
