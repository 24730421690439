import { z } from "zod";

export interface UserInterface {
  id: number;
  full_name: string;
  email: string;
  email_verified_at: string | null;
  is_email_verified: boolean | null;
  username: string;
  DOB: string;
  gender: string;
  country_id: string;
  country: any;
  state_id: string;
  state: any;
  city_id: string;
  city: any;
  area: string | null;
  phone_number: string | null;
  instagram_id: string | null;
  snapchat_id: string | null;
  twitter_x_id: string | null;
  profile_pix_link: string | null;
  verification_code: string | null;
  bio: string | null;
  interest: string | null;
  is_verified: string;
  badge: string | null;
  is_private: string;
  is_email_notification: string;
  created_at: string | null;
  updated_at: string | null;
  last_login: string | null;
  deleted_at: string | null;
  status: string | null;
}

export interface ParamInterface {
  page: number;
  status: string;
  search: string;
  ageRange?: string;
  dateRange: string;
  subscriptionPlan: string;
}

// Define the Zod schema based on your UserFormInterface
export const UserFormSchema = z.object({
  full_name: z
    .string()
    .min(1, "Full name is required")
    .max(255, "Full name cannot exceed 255 characters"),
  email: z.string().email("Invalid email address"),
  username: z
    .string()
    .min(1, "Username is required")
    .max(255, "Username cannot exceed 255 characters"),
  DOB: z
    .string()
    .refine(
      (val) => !isNaN(Date.parse(val)),
      "Date of Birth must be a valid date"
    ),
  gender: z.enum(["MALE", "FEMALE", "OTHERS", ""], {
    required_error: "Gender is required",
    invalid_type_error: "Invalid gender",
  }),
  country_id: z.string().nullable(),
  state_id: z.string().nullable(),
  city_id: z.string().nullable(),
  interest: z.string().nullable(),
  area: z
    .string()
    .min(1, "Area is required")
    .max(255, "Area cannot exceed 255 characters"),
  phone_number: z.string().max(15, "Phone number cannot exceed 15 characters"),
  instagram_id: z
    .string()
    .max(255, "Instagram ID cannot exceed 255 characters"),
  snapchat_id: z.string().max(255, "Snapchat ID cannot exceed 255 characters"),
  twitter_x_id: z.string().max(255, "X ID cannot exceed 255 characters"),
  profile_pix_link: z
    .string()
    .max(255, "Profile picture link cannot exceed 255 characters")
    .nullable()
    .optional(),
  bio: z.string().max(500, "Bio cannot exceed 500 characters").optional(),
  is_private: z.any().optional().default(false),
  is_email_notification: z.any().optional().default(true),
});

// Create a TypeScript type from the schema
export type UserFormType = z.infer<typeof UserFormSchema>;

// .refine((val) => val === null || val > 0, "Invalid country ID"),
