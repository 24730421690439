import React, { useState } from 'react';
import { LuEye, LuEyeOff } from 'react-icons/lu';

type PasswordToggleProps = {
  targetId: string;
};

const PasswordToggle: React.FC<PasswordToggleProps> = ({ targetId }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    const passwordInput = document.getElementById(targetId) as HTMLInputElement;
    if (passwordInput) {
      passwordInput.type = isPasswordVisible ? 'password' : 'text';
    }
  };

  return (
    <>
      <span
        className="text-[#969eab] text-[1.6rem]"
        onClick={togglePasswordVisibility}
        style={{ display: isPasswordVisible ? 'none' : 'inline' }}
      >
        <LuEye />
      </span>
      <span
        className="text-[#969eab] text-[1.6rem]"
        onClick={togglePasswordVisibility}
        style={{ display: isPasswordVisible ? 'inline' : 'none' }}
      >
        <LuEyeOff />
      </span>
    </>
  );
};

export default PasswordToggle;
