// src/context/GlobalStateContext.tsx
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useEffect,
} from "react";

// Define the type of the global state
interface GlobalState {
  email: string | null;
}

// Define the actions that can modify the state
type Action =
  | { type: "SET_EMAIL"; payload: string }
  | { type: "RESET"; payload?: string };

// Initial state
const initialState: GlobalState = {
  email: null,
};

// Read state from localStorage (if available)
const storedState = localStorage.getItem("globalState");
const initialPersistedState = storedState
  ? JSON.parse(storedState)
  : initialState;

// Create context
const GlobalStateContext = createContext<{
  state: GlobalState;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

// Reducer to handle actions
const globalStateReducer = (
  state: GlobalState,
  action: Action
): GlobalState => {
  switch (action.type) {
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
    case "RESET":
      return initialState; // Resets to initial state
    default:
      return state;
  }
};

// Create a provider component
export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(
    globalStateReducer,
    initialPersistedState
  );
  // Persist state to localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem("globalState", JSON.stringify(state));
  }, [state]);
  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook to use global state
export const useGlobalState = () => useContext(GlobalStateContext);
