import PasswordToggle from "../../app-components/PasswordToggle";
import { useState, FormEvent } from "react";
import { loginSchema, loginType } from "../../interface/auth.interface";
import apiClient from "../../api-services/ApiClient";
import { API } from "../../api-services/ApiList";
import { inputStyle } from "../../utils";
import AuthLayout from "./component/AuthLayout";
import { useNavigate } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import { useGlobalState } from "../../context/GlobalStateContext";

const SignIn = () => {
  const navigate = useNavigate();
  const { dispatch } = useGlobalState();

  // component-state
  const [loginData, setLoginData] = useState<loginType>({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState<
    Partial<Record<keyof loginType, string>>
  >({});

  const [serverError, setServerError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  // handle-input-change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // handle-login-submit
  const handleEmailLogin = async (e: FormEvent) => {
    e.preventDefault();
    setErrors({});

    const result = loginSchema.safeParse(loginData);
    if (result.success) {
      setLoading(true);
      try {
        console.log(result.data);

        const res = await apiClient.post(API.LOGIN, result.data);
        //store email in ls;
        dispatch({ type: "SET_EMAIL", payload: result.data.email });

        if (res.data.requires_2fa)
          navigate(SITELINKS.VERIFY_SIGNIN, { state: { fromLogin: true } });
      } catch (error: any) {
        setServerError(JSON.stringify(error));
      } finally {
        setLoading(false);
      }
    } else {
      const formattedErrors: Partial<Record<keyof loginType, string>> = {};

      result.error.errors.forEach((err) => {
        if (err.path.length > 0) {
          const key = err.path[0] as keyof loginType;
          formattedErrors[key] = err.message;
        }
      });

      setErrors(formattedErrors);
    }
  };

  return (
    <AuthLayout title="Admin Login">
      <h4 className="text-2xl text-honeConnectBlack font-bold mb-2.5">Admin</h4>
      <p className="text-sm mb-8 font-semibold">Login for admin only</p>

      {/* react-login-form */}
      <form onSubmit={handleEmailLogin} autoComplete="off" autoCorrect="off">
        <p className="text-red-600 text-[12px] text-center mb-4">
          {serverError}
        </p>

        {/* email input */}
        <div className="flex flex-col text-sm font-medium mb-4">
          <label htmlFor="userID" className="mb-1.5">
            Email
          </label>
          <input
            name="email"
            onChange={handleInputChange}
            className={`${inputStyle}`}
            placeholder="Enter your email"
          />
          <span className="text-red-600 text-[10px]">{errors.email}</span>
        </div>

        {/* password input */}
        <div className="flex flex-col text-sm text-gray-400 font-medium mb-12 relative">
          <label htmlFor="Password" className="mb-1.5">
            Password
          </label>
          <input
            name="password"
            id="password_input"
            type="password"
            onChange={handleInputChange}
            className={`${inputStyle}`}
            placeholder="Enter your password"
          />
          <div className="absolute top-9 right-3">
            <PasswordToggle targetId="password_input" />
          </div>
          <span className="text-red-600 text-[10px]">{errors.password}</span>
        </div>

        <button
          type="submit"
          disabled={loading}
          className="bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base"
        >
          {loading ? "Loading..." : "Continue"}
        </button>
      </form>
    </AuthLayout>
  );
};

export default SignIn;
