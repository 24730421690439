import React from "react";

const Preloader = ({ loading }: { loading: boolean }) => {
  return loading ? (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg flex flex-col items-center">
        <div className="rounded-full border-8 border-gray-300 border-t-8 border-t-purple-500 h-20 w-20 mb-4 animate-spin"></div>
        <p className="text-gray-500 text-sm font-medium">Loading...</p>
      </div>
    </div>
  ) : null;
};

export default Preloader;
