import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { SITELINKS } from "./links";
import { ReactNode } from "react";

// view-pages
import SignIn from "../pages/auth/SignIn";
import AdminVerify from "../pages/auth/AdminVerify";
import Broadcast from "../pages/Broadcast";
import BroadcastHistory from "../pages/BroadcastHistory";
import Dashboard from "../pages/dashboard/Dashboard";
import Request from "../pages/Request";
import Responses from "../pages/Responses";
import Subscriptions from "../pages/subscription/Subscriptions";
import User from "../pages/user/Users";
import ShowUser from "../pages/user/ShowUser";
import useAuth from "./useAuth";
import Preloader from "../app-components/preloader/Preloader";
import CreateProfile from "../pages/user/CreateUser";
import UpdateUser from "../pages/user/UpdateUser";
import Transactions from "../pages/transaction/Transactions";

// Middleware to validate token
function ProtectedAdminRoute({ children }: { children: ReactNode }) {
  const { isAuthenticated, loading } = useAuth();

  // Render a loading indicator until authentication status is determined
  if (loading)
    return (
      <div>
        <Preloader loading={loading} />
      </div>
    );

  // If authenticated, render the children components
  return isAuthenticated ? (
    <>{children}</>
  ) : (
    // If not authenticated, redirect to the SignIn page
    <Navigate to={SITELINKS.SIGNIN} replace />
  );
}

// config routes
function APPRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path={SITELINKS.SIGNIN} element={<SignIn />} />
        <Route path={SITELINKS.VERIFY_SIGNIN} element={<AdminVerify />} />

        <Route
          path={SITELINKS.DASHBOARD}
          element={
            <ProtectedAdminRoute>
              <Dashboard />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.USERS}
          element={
            <ProtectedAdminRoute>
              <User />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={`${SITELINKS.UPDATE_USER}:id`}
          element={
            <ProtectedAdminRoute>
              <UpdateUser />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.CREATE_USER}
          element={
            <ProtectedAdminRoute>
              <CreateProfile />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={`${SITELINKS.USER_PROFILE}:id`}
          element={
            <ProtectedAdminRoute>
              <ShowUser />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.TRANSACTIONS}
          element={
            <ProtectedAdminRoute>
              <Transactions />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.SUBSCRIPTIONS}
          element={
            <ProtectedAdminRoute>
              <Subscriptions />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.BROADCAST}
          element={
            <ProtectedAdminRoute>
              <Broadcast />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.BROADCAST_HISTORY}
          element={
            <ProtectedAdminRoute>
              <BroadcastHistory />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.REQUEST}
          element={
            <ProtectedAdminRoute>
              <Request />
            </ProtectedAdminRoute>
          }
        />

        <Route
          path={SITELINKS.RESPONSES}
          element={
            <ProtectedAdminRoute>
              <Responses />
            </ProtectedAdminRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default APPRoutes;
