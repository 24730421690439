import React from "react";
import {
  dashboardIcon,
  UsersIcon,
  SubscribersIcon,
  RequestsIcon,
  ResponsesIcon,
  Broadcast,
  LogoutIcon,
  caret_down_Icon,
} from "../assets/Icons";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { SITELINKS } from "../app-routes/links";
import { LuAward, LuUserSquare } from "react-icons/lu";

const SideNavAdmin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="md:col-span-3 lg:col-span-2 hidden md:flex flex-col bg-white fixed h-[100vh] w-[250px] mt-2 z-10">
      <Link
        to={SITELINKS.DASHBOARD}
        className={`py-5 px-5 flex items-center gap-3 ${
          location.pathname === "/dashboard" &&
          "border-l-4 border-l-honeConnectPurple border-solid bg-honeConnectGray50"
        }`}
      >
        <img src={dashboardIcon} alt="" />
        <span className="font-semibold text-honeConnectBlack">Dashboard</span>
      </Link>

      <Link
        to={SITELINKS.USERS}
        className={`py-5 px-5 flex items-center justify-between gap-3 ${
          location.pathname.includes("/user") &&
          "border-l-4 border-l-honeConnectPurple border-solid bg-honeConnectGray50"
        }`}
      >
        <div className="flex gap-3">
          <img src={UsersIcon} alt="user-ic" />
          <span className="font-medium text-honeConnectGray">Users</span>
        </div>
        <div>{/* <img src={caret_down_Icon} alt="" /> */}</div>
      </Link>

      <button className="hidden py-5 pl-8 px-5 flex items-center gap-3 border-b-[1px] border-[#F2F1F1] border-solid">
        <div className="flex items-center gap-3">
          <LuUserSquare className="text-2xl" />
          <span className="font-medium text-honeConnectGray">User Profile</span>
        </div>
      </button>

      <Link
        to={SITELINKS.TRANSACTIONS}
        className={`py-5 px-5 flex items-center gap-3 ${
          location.pathname === "/transactions" &&
          "border-l-4 border-l-honeConnectPurple border-solid bg-honeConnectGray50"
        }`}
      >
        <img src={SubscribersIcon} alt="" />
        <span className="font-medium text-honeConnectGray">Transactions</span>
      </Link>

      <Link
        to={SITELINKS.REQUEST}
        className="py-5 px-5 flex items-center gap-3 border-b-[1px] border-[#F2F1F1] border-solid"
      >
        <img src={RequestsIcon} alt="" />
        <span className="font-medium text-honeConnectGray">Requests</span>
      </Link>

      <Link
        to={SITELINKS.RESPONSES}
        className="py-5 px-5 flex items-center gap-3 border-b-[1px] border-[#F2F1F1] border-solid"
      >
        <img src={ResponsesIcon} alt="" />
        <span className="font-medium text-honeConnectGray">Responses</span>
      </Link>

      <Link
        to={SITELINKS.SUBSCRIPTIONS}
        className={`py-5 px-5 flex items-center gap-3 ${
          location.pathname === "/subscriptions" &&
          "border-l-4 border-l-honeConnectPurple border-solid bg-honeConnectGray50"
        }`}
      >
         <LuAward className="text-xl text-gray-500" />
        <span className="font-medium text-honeConnectGray">Subscriptions</span>
      </Link>

      <Link
        to={SITELINKS.BROADCAST}
        className="py-5 px-5  flex items-center justify-between gap-3 border-b-[1px] border-[#F2F1F1] border-solid"
      >
        <div className="flex items-center gap-3">
          <img src={Broadcast} alt="" />
          <span className="font-medium text-honeConnectGray">Broadcast</span>
        </div>
        <div>
          <img src={caret_down_Icon} alt="" />
        </div>
      </Link>
      <button
        onClick={() => {
          localStorage.clear();
          navigate(SITELINKS.SIGNIN);
        }}
        className="py-5 px-5 flex items-center gap-3 border-b-[1px] border-[#F2F1F1] border-solid"
      >
        <img src={LogoutIcon} alt="" />
        <span className="font-medium text-honeConnectGray">Logout</span>
      </button>
    </div>
  );
};

export default SideNavAdmin;
