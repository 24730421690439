import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import apiClient from "../../api-services/ApiClient";
import AdminLayout from "../../app-components/AdminLayout";
import Alert from "../../app-components/alert/Alert";
import Preloader from "../../app-components/preloader/Preloader";
import { SITELINKS } from "../../app-routes/links";
import {
  Icon_Caret_Down,
  Icon_Caret_Left,
  Icon_Caret_Right,
  Icon_Dots,
} from "../../assets/Icons";
import { TransactionInterface } from "../../interface/transactions.interface";
import { ParamInterface } from "../../interface/users.interface";
import { formatAmount, formatDate } from "../../utils";

const Transactions = () => {
  const [pageData, setPageData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pageFilter, setPageFilter] = useState<string>("");

  const [searchInput, setSearchInput] = useState<string>("");
  const [searchInputDebounce, setSearchInputDebounce] = useState<string>("");
  const [dateRange, setDateRange] = useState<string>("");
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  // effect for get users list
  useEffect(() => {
    const params = {
      page: currentPage,
      status: pageFilter,
      search: searchInput,
      dateRange: dateRange,
      subscriptionPlan: subscriptionPlan,
    };

    getTransactions(params);
  }, [currentPage, pageFilter, searchInput, dateRange, subscriptionPlan]);

  // debounce search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setSearchInput(searchInputDebounce);
      setCurrentPage(1);
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchInputDebounce]);

  useEffect(() => {
    setAlertMessage("");
    console.log(startDate, endDate);

    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (end > start) {
        setDateRange(startDate + "," + endDate);
        setCurrentPage(1);
      } else {
        setAlertMessage("Invalid date range.");
      }
    }
    if (!startDate && !endDate) {
      setDateRange("");
    }
  }, [startDate, endDate]);

  const getTransactions = async (params: ParamInterface) => {
    setLoading(true);
    try {
      const res: any = await apiClient.get("transactions", { params });
      if (res && res.data) {
        setPageData(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
      } else {
        setPageData([]);
      }
    } catch (error) {
      console.error("Error fetching:", error);
      setAlertMessage("Unable to fetch records at the moment.");
      setPageData([]);
    } finally {
      setLoading(false);
    }
  };

  // Handle Previous Button Click
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  // Handle Next Button Click
  const handleNext = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleStatusUpdate = async (id: number, status: string) => {
    try {
      // setLoading(true); // Start loading state

      // Create a copy of the current users data for rollback in case of failure
      const previousData = [...pageData];

      // Optimistically update the UI
      const updatedData = pageData.map((transaction: any) =>
        transaction.id === id ? { ...transaction, status } : transaction
      );
      setPageData(updatedData);

      // Make the API call
      const res = await apiClient.patch(`transaction/${id}/status`, { status });

      // Check if the response is successful
      if (res && res.status === 200 && res.data?.transaction) {
        // Replace the updated transaction with the fresh data from the server response
        const refreshedData = pageData.map((transaction: any) =>
          transaction.id === id ? res.data.transaction : transaction
        );

        setPageData(refreshedData);
      } else {
        setPageData(previousData);
      }
    } catch (error) {
      console.error("Error while updating status:", error);
    }
  };

  return (
    <AdminLayout title="Admin | Subscriptions">
      <div className="flex justify-between items-center mb-5">
        <p className="font-semibold text-[25px]">Transactions</p>
      </div>

      {/* <!-- TABLE --> */}
      <div className="py-5 bg-white rounded-lg shadow">
        {/* <!-- tabs --> */}
        <div className="px-5 flex items-center gap-5 mb-5">
          <button
            onClick={() => {
              setPageFilter("");
              setCurrentPage(1);
            }}
            className={`font-medium text-sm ${
              pageFilter === ""
                ? "text-honeConnectPurple border-honeConnectPurple border-b border-solid"
                : "text-honeConnectGray"
            }`}
          >
            All
          </button>
          <button
            onClick={() => {
              setPageFilter("PAID");
              setCurrentPage(1);
            }}
            className={`font-medium text-sm ${
              pageFilter === "PAID"
                ? "text-honeConnectPurple border-honeConnectPurple border-b border-solid"
                : "text-honeConnectGray"
            }`}
          >
            Paid
          </button>
          <button
            onClick={() => {
              setPageFilter("PENDING");
              setCurrentPage(1);
            }}
            className={`font-medium text-sm ${
              pageFilter === "PENDING"
                ? "text-honeConnectPurple border-honeConnectPurple border-b border-solid"
                : "text-honeConnectGray"
            }`}
          >
            Pending
          </button>
          <button
            onClick={() => {
              setPageFilter("FAILED");
              setCurrentPage(1);
            }}
            className={`font-medium text-sm ${
              pageFilter === "FAILED"
                ? "text-honeConnectPurple border-honeConnectPurple border-b border-solid"
                : "text-honeConnectGray"
            }`}
          >
            Failed
          </button>
        </div>

        {/* <!-- filter --> */}
        <div className="px-5 flex justify-between items-center mb-5 flex-wrap gap-y-3">
          <div className="relative w-1/2">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                className="h-5 w-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </span>

            <input
              value={searchInputDebounce}
              onChange={(e) => setSearchInputDebounce(e.target.value)}
              type="text"
              className="pl-10 pr-4 py-3 w-full rounded-lg bg-[#F8F8F8] border border-[#F2F1F1] text-honeConnectGray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm w-full"
              placeholder="Search by tranx id, name, amount paid"
            />
          </div>

          <div className="flex gap-5 w-1/2 justify-end">
            <div className="dropdown relative">
              <button
                type="submit"
                className="dropbtn bg-[#F8F8F8] text-honeConnectPurple p-3 px-4 rounded-[5px] border-2 border-solid border-[#F2F1F1] text-sm font-normal flex items-center gap-3"
              >
                <span className="text-honeConnectGray">Date Range</span>
                <img src={Icon_Caret_Down} alt="" />
              </button>

              <div
                className="flex border p-4 z-10 dropdown-content absolute shadow-lg bg-white rounded-lg"
                style={{ right: "0" }}
              >
                <div className="flex items-center justify-between gap-3 mb-1">
                  <label htmlFor="start-date" className="text-gray-700">
                    Start:
                  </label>
                  <input
                    type="date"
                    id="start-date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border border-gray-300 rounded p-2"
                  />
                </div>
                <div className="flex items-center justify-between gap-3">
                  <label htmlFor="end-date" className="text-gray-700">
                    End:
                  </label>
                  <input
                    type="date"
                    id="end-date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border border-gray-300 rounded p-2"
                  />
                </div>
              </div>
            </div>

            <select
              className="bg-[#F8F8F8] text-honeConnectGray p-3 px-4 rounded-[5px] border-2 border-solid border-[#F2F1F1] text-sm font-normal flex items-center gap-3"
              onChange={(e) => {
                setSubscriptionPlan(e.target.value);
                setCurrentPage(1);
              }}
              defaultValue={""}
            >
              <option value={""} disabled>
                Plan
              </option>
              <option value={""}>ALL</option>
              <option value={"STANDARD"}>Standard</option>
              <option value={"BRONZE"}>Bronze</option>
              <option value={"SILVER"}>Silver</option>
              <option value={"GOLD"}>Gold</option>
            </select>
          </div>
        </div>

        {/* <!-- record TABLE --> */}
        <div className="overflow-x-auto mb-5 overflow-y-auto">
          <table className="min-w-full bg-white mb-4">
            <thead>
              <tr className="w-full bg-[#E4E1E1] text-gray-600 capitalize text-sm leading-normal">
                <th className="py-3 px-6 text-left">
                  <input
                    type="checkbox"
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                </th>
                <th className="py-3 px-6 text-left">Tranx Id</th>
                <th className="py-3 px-6 text-left">Name</th>
                <th className="py-3 px-6 text-left hidden">Email</th>
                <th className="py-3 px-6 text-left">Amount Paid</th>
                <th className="py-3 px-6 text-left">Plan</th>
                <th className="py-3 px-6 text-left hidden">Plan Price</th>
                <th className="py-3 px-6 text-left">Method</th>
                <th className="py-3 px-6 text-left">Status</th>
                <th className="py-3 px-6 text-left">Date</th>
                <th className="py-3 px-6 text-center hidden">Action</th>
              </tr>
            </thead>

            {!!pageData?.length && (
              <tbody className="text-gray-600 text-sm font-light">
                {pageData?.map((transaction: TransactionInterface) => {
                  return (
                    <tr
                      key={transaction.id}
                      className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]"
                    >
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.transaction_code ?? "No Trans Id"}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.subscription.user.full_name ?? "No Name"}
                      </td>
                      <td className="py-3 px-6 text-left hidden">
                        {transaction.subscription.user.email ?? "No Email"}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {formatAmount(transaction.amount)}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.subscription.plan.name}
                      </td>
                      <td className="py-3 px-6 text-left hidden">
                        {formatAmount(transaction.subscription.plan.price)}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.payment_method}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.status === "PAID" && (
                          <span className="bg-[#E7F7EF] text-[#0CAF60] p-1 rounded-[5px] text-sm font-medium">
                            Paid
                          </span>
                        )}
                        {transaction.status === "FAILED" && (
                          <span className="bg-red-100 text-red-800 p-1 rounded-[5px] text-sm font-medium">
                            Failed
                          </span>
                        )}
                        {transaction.status === "PENDING" && (
                          <span className="bg-blue-100 text-blue-800 p-1 rounded-[5px] text-sm font-medium">
                            Pending
                          </span>
                        )}
                      </td>
                      <td className="py-3 px-6 text-left">
                        {transaction.created_at
                          ? formatDate(transaction.created_at, "date")
                          : ""}
                      </td>
                      <td className="py-3 px-6 text-center hidden">
                        <div className="dropdown relative">
                          <button
                            title="More Options"
                            className="text-blue-600 dropbtn"
                          >
                            <img
                              src={Icon_Dots}
                              alt=""
                              className="text-center"
                            />
                          </button>
                          <div
                            className="dropdown-content absolute shadow-lg bg-white rounded-lg z-10 w-[120px] mb-12"
                            style={{ right: "0" }}
                          >
                            <button
                              className="hidden rounded-t-lg block p-2 text-center w-full hover:bg-honeConnectPurple hover:text-white"
                              onClick={() => {
                                handleStatusUpdate(transaction.id, "ACTIVE");
                              }}
                            >
                              Paid
                            </button>

                            <button
                              className="hidden block p-2 text-center w-full hover:bg-honeConnectPurple hover:text-white"
                              onClick={() => {
                                handleStatusUpdate(transaction.id, "CANCELED");
                              }}
                            >
                              Cancel
                            </button>

                            <Link
                              to={SITELINKS.USER_PROFILE + transaction.id}
                              className="hidden rounded-b-lg block p-2 text-center w-full hover:bg-honeConnectPurple hover:text-white block"
                            >
                              View Info.
                            </Link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>

          {!pageData?.length && (
            <p className="text-center p-5">No record found</p>
          )}
        </div>

        {/* <!-- ROW PAGINATION --> */}
        <div className="flex justify-center gap-3 flex-wrap">
          {/* Previous Button */}
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`bg-[#fff] text-honeConnectGray p-3 px-4 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
              currentPage === 1
                ? "disabled:text-gray-400 disabled:cursor-not-allowed"
                : ""
            }`}
          >
            <span>Previous</span>
          </button>

          {/* Left Arrow */}
          <button
            disabled={currentPage === 1}
            onClick={handlePrevious}
            className={`bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
              currentPage === 1
                ? "disabled:text-gray-400 disabled:cursor-not-allowed"
                : ""
            }`}
          >
            <img src={Icon_Caret_Left} alt="" />
          </button>

          {/* Page Numbers */}
          {Array.from(
            { length: lastPage > 7 ? 3 : lastPage },
            (_, i) => i + 1
          ).map((page) => (
            <button
              title={`page ${page}`}
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
                page === currentPage
                  ? "bg-honeConnectPurple text-white"
                  : "bg-[#fff] text-honeConnectGray"
              }`}
            >
              <span>{page}</span>
            </button>
          ))}

          {lastPage > 7 && (
            <>
              {/* ellipse Arrow */}
              <button
                disabled
                className={`bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium`}
              >
                <img src={Icon_Dots} alt="" className="text-center" />
              </button>

              {/* Page Numbers */}
              {Array.from({ length: 3 }, (_, i) => 2 - i).map((page) => (
                <button
                  title={`page ${lastPage - page}`}
                  key={lastPage - page}
                  onClick={() => setCurrentPage(lastPage - page)}
                  className={`p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
                    lastPage - page === currentPage
                      ? "bg-honeConnectPurple text-white"
                      : "bg-[#fff] text-honeConnectGray"
                  }`}
                >
                  <span>{lastPage - page}</span>
                </button>
              ))}
            </>
          )}

          {/* Right Arrow */}
          <button
            disabled={currentPage === lastPage}
            onClick={handleNext}
            className={`bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
              currentPage === lastPage
                ? "disabled:text-gray-400 disabled:cursor-not-allowed"
                : ""
            }`}
          >
            <img src={Icon_Caret_Right} alt="" />
          </button>

          {/* Next Button */}
          <button
            onClick={handleNext}
            disabled={currentPage === lastPage}
            className={`bg-[#fff] text-honeConnectGray p-3 px-4 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium ${
              currentPage === lastPage
                ? "disabled:text-gray-400 disabled:cursor-not-allowed"
                : ""
            }`}
          >
            <span>Next</span>
          </button>
        </div>
      </div>

      <Preloader loading={loading} />
      <Alert message={alertMessage} />
    </AdminLayout>
  );
};

export default Transactions;
