import React, { useEffect, ReactNode } from "react";
import { LadyLookingAtPhone } from "../../../assets/img";

type HeadProps = { title: string };
type LayoutProps = { children?: ReactNode; title?: string };

const Head: React.FC<HeadProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

const AuthLayout = ({ children, title = "" }: LayoutProps) => (
  <main>
    <Head title={title} />
    <section className="grid lg:grid-cols-5 w-full mx-auto p-8">
      <div className="hidden lg:block lg:col-span-2 overflow-hidden">
        <img src={LadyLookingAtPhone} alt="" className="lg:w-auto h-[92vh]" />
      </div>

      <div className="py-4 px-0 lg:col-span-3 h-[90vh] justify-center flex items-center">
        <div className="w-full pt-8 lg:pt-20 pb-0 px-10 md:pr-52 md:pl-40 lg:px-10 xl:px-40 2xl:px-60 text-honeConnectGray">
          {children}
        </div>
      </div>
    </section>
  </main>
);

export default AuthLayout;
