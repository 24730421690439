import React from "react";

interface AlertProps {
  message: string;
}

const Alert: React.FC<AlertProps> = ({ message }) => {
  if (!message) return null;

  return (
    <div className="fixed top-0 z-10 right-0 m-6 mx-8 p-4 px-8 bg-red-500 text-white rounded shadow-lg">
      {message}
    </div>
  );
};

export default Alert;
