import { LuChevronLeft } from "react-icons/lu";
import AuthLayout from "./component/AuthLayout";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { SITELINKS } from "../../app-routes/links";
import OTPInput from "react-otp-input";
import { FormEvent, useEffect, useState } from "react";
import apiClient from "../../api-services/ApiClient";
import { API } from "../../api-services/ApiList";
import { useGlobalState } from "../../context/GlobalStateContext";
import { storeToken, storeUser } from "../../api-services/Process";

const AdminVerify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useGlobalState();

  const [loading, setLoading] = useState<boolean>(false);
  const [resendCode, setResendCode] = useState<boolean>(true);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState(600);

  useEffect(() => {
    if (!location.state || !location.state.fromLogin) {
      navigate(SITELINKS.SIGNIN);
    }
  }, [location, navigate]);

  useEffect(() => {
    setEmail(state.email ?? "");
  }, [state.email]);

  useEffect(() => {
    if (timeLeft === 0) {
      setResendCode(false);
    }
    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      // Clear the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [timeLeft]);

  // Format time into MM:SS
  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOTPChange = (otp: any) => {
    setOtp(otp);
  };

  // handle-login-submit
  const handle2FA = async (e: FormEvent) => {
    e.preventDefault();
    const result = otp.length === 6;

    setError("");
    if (!result) {
      setError("code must be 6 digit in length");
      return;
    }

    setLoading(true);
    const data = {
      email: email,
      code: otp,
    };

    try {
      const res = await apiClient.post(API.LOGIN_2FA, data);

      storeToken(res.data?.access_token);
      storeUser(res.data?.user);
      navigate(SITELINKS.DASHBOARD);
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  // handle-login-submit
  const handleResend2FA = async (e: FormEvent) => {
    e.preventDefault();

    setError("");
    if (!email) {
      setError("Cannot complete process, kindly return to sign in");
      return;
    }

    setLoading(true);
    const data = {
      email: email,
    };

    try {
      await apiClient.post(API.RESEND_2FA_CODE, data);
      setTimeLeft(600);
    } catch (error: any) {
      setError(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="Admin | 2FA Verification">
      <Link
        to={SITELINKS.SIGNIN}
        className="text-honeConnectPurple flex items-center gap-0 text-sm font-semibold"
      >
        <span className="text-2xl">
          <LuChevronLeft />
        </span>
        <span>Back</span>
      </Link>

      <div className="w-full pt-8 lg:pt-40 pb-0 px-10 text-honeConnectGray text-center">
        <h4 className="text-2xl text-honeConnectBlack font-bold mb-2.5">
          Enter Verification Code
        </h4>

        <div className="text-sm mb-4 font-semibold">
          <p>A 6-digit code was sent to your email address</p>
          <span className="text-honeConnectPurple">{email}</span>
        </div>

        <form
          className="mt-12"
          onSubmit={handle2FA}
          autoComplete="off"
          autoCorrect="off"
        >
          <p className="text-red-600 text-[12px] text-center mb-4">{error}</p>

          <div className="flex justify-center items-center mb-10">
            <OTPInput
              value={otp}
              onChange={handleOTPChange}
              numInputs={6}
              shouldAutoFocus
              inputStyle={{
                width: "3.5rem",
                height: "3.5rem",
                margin: "0 1rem 0 0",
                fontSize: "1.5rem",
                borderRadius: 15,
                border: "1px solid #6d7986",
                background: "#f8f8f8",
              }}
              renderInput={(props) => <input {...props} />}
            />
          </div>

          <p className="text-honeConnectOrange text-sm font-semibold text-center mb-5">
            Code expires in {formatTime(timeLeft)}
          </p>

          <p className="text-sm font-semibold text-center mb-5">
            Didn’t receive the code?
          </p>

          <button
            onClick={handleResend2FA}
            type="button"
            disabled={resendCode}
            className="disabled:text-gray-400 disabled:cursor-not-allowed text-honeConnectPurple text-sm font-semibold text-center mb-10"
          >
            Resend code
          </button>

          <button
            type="submit"
            className="bg-honeConnectPurple text-white w-full p-3 rounded-lg border-0 text-base mb-[25vh]"
          >
            {loading ? "Loading..." : "Continue"}
          </button>
        </form>
      </div>
    </AuthLayout>
  );
};

export default AdminVerify;
