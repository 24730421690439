import React from "react";

const FooterAdmin = () => {
  return (
    <footer className="bg-honeConnectPurple text-white pt-6 pb-6">
      <div className="font-medium text-sm text-center">
        Hone Connect Copyright @2024. All rights reserved
      </div>
    </footer>
  );
};


export default FooterAdmin;
