import React, { useEffect, ReactNode } from "react";
import HeaderAdmin from "./HeaderAdmin";
import SideNavAdmin from "./SideNavAdmin";
import FooterAdmin from "./FooterAdmin";

type HeadProps = { title: string };
type LayoutProps = { children?: ReactNode; title?: string };

const Head: React.FC<HeadProps> = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

const AdminLayout = ({ children, title = "" }: LayoutProps) => (
  <main>
    <Head title={title} />
    <main className="bg-hc_F4EBFA">
      <HeaderAdmin />

      <section className="mt-24">
        <div className="grid grid-cols-12 justify-between">
          <SideNavAdmin />

          <div className="col-span-12 pl-[250px]">
            <div className="py-8 px-7">{children}</div>
            <FooterAdmin />
          </div>
        </div>
      </section>
    </main>
  </main>
);

export default AdminLayout;
