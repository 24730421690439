export const inputStyle =
  "w-full bg-honeConnectGray50 border border-solid border-honeConnectGray200 text-honeConnectGray200 p-3.5 rounded-md focus:outline-1 focus:outline focus:outline-honeConnectPurple font-normal";

export function twoDecimalPlace(value: number) {
  if (value) return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "0";
}

export function formatDate(date: string, format?: string) {
  let d = new Date(date);

  if (format === "time") {
    return d.toLocaleTimeString("en-US");
  } else if (format === "date") {
    let y = d.getFullYear();
    let m = d.getMonth();
    let dd = d.getDate();
    let months_of_year = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${months_of_year[m] + " " + dd + ", " + y}`;
  } else {
    return d.toDateString() + " " + d.toLocaleTimeString("en-US");
  }
}

export const formatAmount = (amount:any) => {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  }).format(Number(amount ?? "0.00"));

  return formattedAmount;
};
