import { Link } from "react-router-dom";
import { getUser } from "../api-services/Process";
import HoneConnectLogoHeader1 from "../assets/img/HoonConnectLogoHeader1.png";
import userAdminImg from "../assets/img/User-admin.png";
const HeaderAdmin = () => {
  const user = getUser();

  return (
    <header className="bg-white fixed top-0 right-0 left-0 z-10">
      <div className="flex justify-between items-center w-11/12 mx-auto py-4">
        <div>
          <img
            src={HoneConnectLogoHeader1}
            alt="Hone Connect Landing page"
            className="w-40"
          />
        </div>
        <div className="flex items-center gap-6">
          <div className="text-lg">Hello, {user?.name}</div>
          <div>
            <Link to="/">
              <img src={userAdminImg} alt="User Display" className="w-12" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderAdmin;
