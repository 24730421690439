import React from "react";
import "./App.css";
import APPRoutes from "./app-routes";
import { GlobalStateProvider } from "./context/GlobalStateContext";

function App() {
  return (
    <GlobalStateProvider>
      <APPRoutes />
    </GlobalStateProvider>
  );
}

export default App;
