import HeaderAdmin from "../app-components/HeaderAdmin";
import SideNavAdmin from "../app-components/SideNavAdmin";
import FooterAdmin from "../app-components/FooterAdmin";
import Icon_Caret_Down from "../assets/Icons/caret-down.svg";
import Icon_Caret_Left from "../assets/Icons/caret-left.svg";
import Icon_Caret_Right from "../assets/Icons/caret-right.svg";
import Icon_Dots from "../assets/Icons/dots.svg";

const Responses = () => {
  return (
    <div className="bg-hc_F4EBFA">
      <HeaderAdmin />
      <main>
        <div className="grid grid-cols-12 gap-8 justify-between">
          {/* <!-- Column 3 --> */}
          <SideNavAdmin />
          {/* <!-- COlumn 9 --> */}
          <div className="col-span-12 md:col-span-9 lg:col-span-10 py-10 px-7">
            {/* <!-- ROW --> */}
            <div className="flex justify-between items-center mb-5">
              <p className="font-semibold text-[25px]">Responses</p>
            </div>

            {/* <!-- TABLE --> */}
            <div className="py-5 bg-white rounded-lg shadow">
              {/* <!-- ROW --> */}
              <div className="px-5 flex items-center gap-5 mb-5">
                <a
                  href="#!"
                  className="font-medium text-sm text-honeConnectPurple border-b border-solid border-honeConnectPurple"
                >
                  All Request
                </a>
              </div>
              {/* <!-- ROW --> */}
              <div className="px-5 flex justify-between items-center mb-5 flex-wrap gap-y-3">
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <svg
                      className="h-5 w-5 text-gray-500"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="pl-10 pr-4 py-3 sm:w-64 lg:w-[550px] rounded-lg bg-[#F8F8F8] border border-[#F2F1F1] text-honeConnectGray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
                    placeholder="Search by name, username, unique number, location or interest"
                  />
                </div>
                <div className="flex gap-5">
                  <button
                    type="submit"
                    className="bg-[#F8F8F8] text-honeConnectPurple p-3 px-4 rounded-[5px] border-2 border-solid border-[#F2F1F1] text-sm font-normal flex items-center gap-3"
                  >
                    <span className="text-honeConnectGray">Data Range</span>
                    <img src={Icon_Caret_Down} alt="" />
                  </button>
                </div>
              </div>
              {/* <!-- ROW TABLE --> */}
              <div className="overflow-x-auto mb-10">
                <table className="min-w-full bg-white shadow">
                  <thead>
                    <tr className="w-full bg-[#E4E1E1] text-gray-600 capitalize text-sm leading-normal">
                      <th className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </th>
                      <th className="py-3 px-6 text-left">Username</th>
                      <th className="py-3 px-6 text-left">Unique No</th>
                      <th className="py-3 px-6 text-left">Date</th>
                      <th className="py-3 px-6 text-left">Interest</th>
                      <th className="py-3 px-6 text-left">Plan</th>
                      <th className="py-3 px-6 text-left">Responses</th>
                      <th className="py-3 px-6 text-left">
                        <img src={Icon_Dots} alt="" />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    {/* <!-- Table Rows --> */}
                    {/* <!-- {% for i in range(10) %} --> */}
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">Okafore93</td>
                      <td className="py-3 px-6 text-left">787434</td>
                      <td className="py-3 px-6 text-left">03 Mar 2024</td>
                      <td className="py-3 px-6 text-left">Friends</td>
                      <td className="py-3 px-6 text-left">Gold</td>
                      <td className="py-3 px-6 text-left">30</td>

                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    {/* <!-- {% endfor %} --> */}
                  </tbody>
                </table>
              </div>
              {/* <!-- ROW PAGINATION --> */}
              <div className="flex justify-center gap-3 flex-wrap">
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-4 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>Previous</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-[#6D6D6D] p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <img src={Icon_Caret_Left} alt="" />
                </button>
                <button
                  type="submit"
                  className="bg-honeConnectPurple text-white p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>1</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>2</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>3</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>4</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>5</span>
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-[#6D6D6D] p-3 px-5 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <img src={Icon_Caret_Right} alt="" />
                </button>
                <button
                  type="submit"
                  className="bg-[#fff] text-honeConnectGray p-3 px-4 rounded-[14px] border-2 border-solid border-[#F2F1F1] text-base font-medium"
                >
                  <span>Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterAdmin />
    </div>
  );
};

export default Responses;
