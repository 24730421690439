import React from "react";
import HeaderAdmin from "../app-components/HeaderAdmin";
import SideNavAdmin from "../app-components/SideNavAdmin";
import FooterAdmin from "../app-components/FooterAdmin";
import Icon_Caret_Down from "../assets/Icons/caret-down.svg";
import Icon_Dots from "../assets/Icons/dots.svg";
import { Link } from "react-router-dom";

const Broadcast = () => {
  return (
    <div className="bg-hc_F4EBFA">
      <HeaderAdmin />
      <main>
        <div className="grid grid-cols-12 gap-8 justify-between">
          {/* <!-- Column 3 --> */}
          <SideNavAdmin />
          {/* <!-- COlumn 9 --> */}
          <div className="col-span-12 md:col-span-9 lg:col-span-10 py-10 px-7">
            {/* <!-- ROW --> */}
            <div className="flex justify-between items-center mb-5">
              <p className="font-semibold text-[25px]">Broadcast</p>
            </div>

            {/* <!-- TABLE --> */}
            <div className="py-5 pb-2 bg-white rounded-lg shadow">
              {/* <!-- ROW --> */}
              <p className="px-5 mb-5 font-medium text-xl text-[#202224]">
                New Broadcast Message
              </p>
              {/* <!-- ROW --> */}
              <div className="px-5 mb-5">
                <input
                  type="text"
                  className="p-3 w-full rounded-lg bg-[#F8F8F8] border border-[#F2F1F1] text-honeConnectGray focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm"
                  placeholder="Add Message"
                />
                <p className="mt-2 text-right text-sm font-normal">
                  <span className="text-[#FD6A6A]">0</span>/230 Characters
                </p>
              </div>
              {/* <!-- ROW --> */}
              <div className="px-5 mb-10">
                <button
                  type="submit"
                  className="bg-honeConnectPurple text-white py-3 px-16 rounded-lg border-0 text-base"
                >
                  Send Message
                </button>
              </div>
              {/* <!-- ROW --> */}
              <div className="px-5 mb-5">
                {/* <!-- ROW --> */}
                <p className="font-medium text-xl text-[#202224] mb-3">
                  Broadcast History
                </p>
                <button
                  type="submit"
                  className="bg-[#F8F8F8] text-honeConnectPurple p-3 px-4 rounded-[5px] border-2 border-solid border-[#F2F1F1] text-sm font-normal flex items-center gap-3"
                >
                  <span className="text-honeConnectGray">Data Range</span>
                  <img src={Icon_Caret_Down} alt="" />
                </button>
              </div>
              {/* <!-- ROW TABLE --> */}
              <div className="overflow-x-auto mb-2">
                <table className="min-w-full bg-white shadow">
                  <thead>
                    <tr className="w-full bg-[#E4E1E1] text-gray-600 capitalize text-sm leading-normal">
                      <th className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </th>
                      <th className="py-3 px-6 text-left">Date</th>
                      <th className="py-3 px-6 text-left">Message</th>
                      <th className="py-3 px-6 text-left">Read</th>
                      <th className="py-3 px-6 text-left">Unread</th>
                      <th className="py-3 px-6 text-left">
                        <img src={Icon_Dots} alt="" />
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                    {/* <!-- Table Rows --> */}
                    {/* <!-- {% for i in range(10) %} --> */}
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">
                        15 Feb 2024 12:55:35PM
                      </td>
                      <td className="py-3 px-6 text-left">
                        Duis faucibus egestas turpis in dignissim Mauris ac
                        pretium nunc Mauris ac pretium nunc Vestibulum
                        ullamcorper sagittis nibh at fin. . (More)
                      </td>
                      <td className="py-3 px-6 text-left text-[#0CAF60]">
                        1,842
                      </td>
                      <td className="py-3 px-6 text-left text-[#FD6A6A]">
                        172
                      </td>
                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">
                        15 Feb 2024 12:55:35PM
                      </td>
                      <td className="py-3 px-6 text-left">
                        Duis faucibus egestas turpis in dignissim Mauris ac
                        pretium nunc Mauris ac pretium nunc Vestibulum
                        ullamcorper sagittis nibh at fin. . (More)
                      </td>
                      <td className="py-3 px-6 text-left text-[#0CAF60]">
                        1,842
                      </td>
                      <td className="py-3 px-6 text-left text-[#FD6A6A]">
                        172
                      </td>
                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">
                        15 Feb 2024 12:55:35PM
                      </td>
                      <td className="py-3 px-6 text-left">
                        Duis faucibus egestas turpis in dignissim Mauris ac
                        pretium nunc Mauris ac pretium nunc Vestibulum
                        ullamcorper sagittis nibh at fin. . (More)
                      </td>
                      <td className="py-3 px-6 text-left text-[#0CAF60]">
                        1,842
                      </td>
                      <td className="py-3 px-6 text-left text-[#FD6A6A]">
                        172
                      </td>
                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    <tr className="border-b border-[#F2F1F1] hover:bg-gray-100 font-medium text-[#232630]">
                      <td className="py-3 px-6 text-left">
                        <input
                          type="checkbox"
                          className="form-checkbox h-4 w-4 text-blue-600"
                        />
                      </td>
                      <td className="py-3 px-6 text-left">
                        15 Feb 2024 12:55:35PM
                      </td>
                      <td className="py-3 px-6 text-left">
                        Duis faucibus egestas turpis in dignissim Mauris ac
                        pretium nunc Mauris ac pretium nunc Vestibulum
                        ullamcorper sagittis nibh at fin. . (More)
                      </td>
                      <td className="py-3 px-6 text-left text-[#0CAF60]">
                        1,842
                      </td>
                      <td className="py-3 px-6 text-left text-[#FD6A6A]">
                        172
                      </td>
                      <td className="py-3 px-6 text-center">
                        <a href="#!">
                          <img src={Icon_Dots} alt="" className="text-center" />
                        </a>
                      </td>
                    </tr>
                    {/* <!-- {% endfor %} --> */}
                  </tbody>
                </table>
              </div>
              <div className="px-5 mt-2 text-right">
                <Link
                  to="/"
                  className="text-sm font-normal text-honeConnectPurple"
                >
                  View All History
                </Link>
              </div>
            </div>
          </div>
        </div>
      </main>
      <FooterAdmin />
    </div>
  );
};

export default Broadcast;
