import { useState, useEffect } from "react";
import apiClient from "../../api-services/ApiClient";
import AdminLayout from "../../app-components/AdminLayout";
import {
  Icon_Active_Users,
  Icon_Removed_Users,
  Icon_TotalUsers,
  Icon_Total_Revenue,
} from "../../assets/Icons";
import { Revenue_OverviewGraph } from "../../assets/img";
import { twoDecimalPlace } from "../../utils";

interface dashboardInterface {
  total_revenue: number;
  total_users: number;
  active_users: number;
  removed_users: number;
  revenue_overview: any; //yet to be populated
  device_traffic: any; //yet to be populated
}

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState<dashboardInterface>();

  useEffect(() => {
    getDashboard();
  }, []);

  const getDashboard = async () => {
    const res: any = await apiClient.get("dashboard");
    setDashboardData(res.data);
  };

  return (
    <AdminLayout title="Admin | Dashboard">
      <p className="font-semibold text-[25px]">Dashboard</p>

      {/* <!-- count stat --> */}
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5 mt-3">
        <div className="flex flex-col gap-3 bg-white rounded-10px p-6">
          <img
            src={Icon_Total_Revenue}
            alt=""
            className="w-12 bg-[#D9D9D9] p-3 rounded-10px"
          />
          <p className="font-medium text-base text-honeConnectGray">
            Total Revenue
          </p>
          <p className="font-semibold text-3xl text-honeConnectBlack">
            ₦{twoDecimalPlace(dashboardData?.total_revenue ?? 0)}
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-10px p-6">
          <img
            src={Icon_TotalUsers}
            alt=""
            className="w-12 bg-[#D9D9D9] p-3 rounded-10px"
          />
          <p className="font-medium text-base text-honeConnectGray">
            Total Users
          </p>
          <p className="font-semibold text-3xl text-honeConnectBlack">
            {dashboardData?.total_users.toLocaleString() ?? 0}
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-10px p-6">
          <img
            src={Icon_Active_Users}
            alt=""
            className="w-12 bg-[#D9D9D9] p-3 rounded-10px"
          />
          <p className="font-medium text-base text-honeConnectGray">
            Active Users
          </p>
          <p className="font-semibold text-3xl text-honeConnectBlack">
            {dashboardData?.active_users.toLocaleString() ?? 0}
          </p>
        </div>
        <div className="flex flex-col gap-3 bg-white rounded-10px p-6">
          <img
            src={Icon_Removed_Users}
            alt=""
            className="w-12 bg-[#D9D9D9] p-3 rounded-10px"
          />
          <p className="font-medium text-base text-honeConnectGray">
            Removed Users
          </p>
          <p className="font-semibold text-3xl text-honeConnectBlack">
            {dashboardData?.removed_users.toLocaleString() ?? 0}
          </p>
        </div>
      </div>

      {/* <!-- line graph --> */}
      <div className="bg-white rounded-10px p-6 mt-5">
        <p className="font-medium text-[20px] mb-10">Revenue Overview</p>
        <a href="#!">
          <img src={Revenue_OverviewGraph} alt="" className="w-full" />
        </a>
      </div>

      {/* <!-- progress bar --> */}
      <div className="bg-white rounded-10px p-6 mt-5">
        <p className="font-semibold text-[20px] mb-5">Weekly Device Traffic</p>

        <div className="flex gap-5">
          <div>
            <p className="font-semibold text-[16px] mb-2">Channel</p>
            <p className="font-medium text-[14px] mb-4">Desktop</p>
            <p className="font-medium text-[14px] mb-4">Mobile</p>
          </div>

          <div className="w-full">
            <p className="font-semibold text-[16px] mb-5">&nbsp;</p>
            <div className="font-medium text-[14px] mb-8 w-full h-1 bg-gray-300">
              <p className="font-medium text-[14px] mb-6 w-2/3 h-1 bg-honeConnectPurple rounded-lg">
                &nbsp;
              </p>
            </div>
            <div className="font-medium text-[14px] mb-8 w-full h-1 bg-gray-300">
              <p className="font-medium text-[14px] mb-6 w-1/3 h-1 bg-honeConnectOrange rounded-lg">
                &nbsp;
              </p>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
